import { useLanguage } from './LanguageContext';

function Cv() {
    const { language } = useLanguage();

    let text = "";

    if (language === "EN") {
        text = <div className="cv">
                    <section id="cinema-tv">
                        <h1>Cinema & TV</h1>
                        <ul>
                            <li><strong>June 2023</strong> | 'Kurt Ralske' in the feature film <strong>Segundo Premio</strong>, directed by <strong>Isaki Lacuesta</strong>.</li>
                            <li><strong>June 2024</strong> | 'Jaime' in the Disney+ TV Series <strong>Olimpia</strong>, directed by <strong>Violeta Salama</strong>.</li>
                            <li><strong>March 2021</strong> | 'Adrián Alcántara' in the Spanish TV series <strong>Cuéntame Cómo Pasó</strong>.</li>
                            <li><strong>February 2021</strong> | A cameo in the feature film <strong>Maixabel</strong>, directed by <strong>Icíar Bollaín</strong>.</li>
                            <li><strong>August 2021</strong> | Waiter in the feature film <strong>The Restoration</strong>, directed by <strong>David Mateo</strong>.</li>
                            <li><strong>April 2021</strong> | 'Martin' in the TV series <strong>Larrugorritan</strong> directed by <strong>Jone Arriola</strong>.</li>
                            <li><strong>December 2020</strong>  | Directed and starred the short film <strong>El Sorteo de Navidad</strong>. Received a special mention in the 'Cultura Inquieta' Christmas short film competition.</li>
                            <li><strong>November 2020</strong> | Cocreated and starred the music video <strong>Pareidolia</strong> for the music band Habi.</li>
                            <li><strong>May 2020</strong> | Directed and starred the experimental short film <strong>Estado de Contención</strong>, received the award for craziest short film in the 'Amokna' competition, organized by 'Escuela de Creación Escenica de Madrid'.</li>
                        </ul>
                    </section>
                    <section id="theatre">
                        <h1>Theatre</h1>
                        <ul>
                            <li><strong>June 2022 - June 2024</strong> | 'Policeman' in the play <strong>Napolitana</strong> directed by <strong>Marta Barrera</strong>.</li>
                            <li><strong>November 2021</strong> | Dramatic Readings of the texts 'Granada' by Yanis Klavrianós, 'The Things You Carry with You' by Andreas Flurakis y 'Antigona, A Requiem' by Thomas Kock directed by <strong>Víctor Velasco</strong> for the Eurodram Assembly.</li>
                            <li><strong>June 2021</strong> | Lysander in the play <strong>'Sueño. Noche. Verano.' (Midnight. Summer. Dream.)</strong> directed by <strong>Gabriel Olivares</strong>.</li>
                            <li><strong>July 2021 - August 2021</strong> | Starred the theatrical adaptation of <strong>The Trial of Joe Harlan Junior</strong> by JK Franko, directed by <strong>Tony Marturano</strong>.</li>
                        </ul>
                    </section>
                    <section id="education">
                        <h1>Education</h1>
                        <ul>
                            <li><strong>September 2020 - February 2023</strong> | Graduated in Acting at the <strong>Work In Progress Acting School</strong> in Madrid directed by the theatre director <strong>Darío Facal</strong>.</li>
                            <li><strong>July 2022</strong> | 'International Actors Bootcamp' workshop conducted by <strong>Luci Lennox, Camilla-Valentine Isola, Sophie Holland y Nathalie Cheron</strong> at <strong>Frank Stein Studio</strong>.</li>
                            <li><strong>January 2022 - February 2022</strong> | 'El gesto sonoro. La palabra en movimiento' workshop at the <strong>Teatro Español (Spanish National Theatre)</strong> with <strong>Natalia Menéndez, Mey-Ling Bisogno y José Gabriel López Antuñano</strong>.</li>
                            <li><strong>February 2023 - April 2023</strong> | 'Acting in English' course conducted by casting directors <strong>Luci Lennox and Camilla-Valentina Isola</strong>.</li>
                            <li><strong>October 2023 - February 2024</strong> | 'Acting in English' course conducted by actor <strong>David Luque</strong> at the Work In Progress Acting School in Madrid.</li>
                            <li><strong>October 2023 - February 2024</strong> | 'Lee Strasberg Method' course conducted by actress <strong>Bárbara Santa-Cruz</strong> at the Work In Progress Acting School in Madrid.</li>
                            <li><strong>March 2022 - May 2022</strong> | 'Meisner Method' course conducted by actress <strong>Yolanda Vega</strong> at the Work In Progress Acting School in Madrid.</li>
                            <li><strong>June 2021 - October 2021</strong> | Suzuki and Viewpoints training with Gabriel Olivares and Andrés Acevedo at the <strong>TeatroLAB</strong> creation centre.</li>
                            <li><strong>June 2019</strong> | Graduated in Acting at the <strong>Barcelona International Film School (ECIB)</strong>.</li>
                            <li><strong>June 2021 - June 2022</strong> | Training for actors directed by Andoni Larrabeiti at <strong>LaJoven</strong> theatre company.</li>
                            <li><strong>February 2021 - May 2021</strong> | Musical Theatre course with the Musical theatre actress <strong>Carmen Conesa</strong> at the Work In Progress Acting School in Madrid.</li>
                            <li><strong>April 2020</strong> | Actor Marketing Workshop conducted by film director <strong>David Victori</strong>.</li>
                        </ul>
                    </section>
                    <section id="advertising">
                        <h1>Advertising</h1>
                        <ul>
                            <li><strong>December 2022</strong> | Actor for a Laboral Kutxa advert directed by Asier Urbieta.</li>
                            <li><strong>February 2022</strong> | Actor for a San Miguel Magna advert.</li>
                            <li><strong>December 2021</strong> | Actor for the 'Squid Game' branded clothing social network campaing for Pull&Bear.</li>
                            <li><strong>December 2021</strong> | Actor for an ING Direct advert.</li>
                            <li><strong>May 2021</strong> | Starred a VIPS advert directed by César Conti.</li>
                            <li><strong>July 2020</strong> | Starred a Euromillions advert directed by Félix Fernández de Castro.</li>
                            <li><strong>July 2020</strong> | Actor for a Caser Seguros advert directed by Bernat Lliteras.</li>
                            <li><strong>June 2020</strong> | Actor for a Puma x Valencia CF advert directed by Mario Lerma.</li>
                            <li><strong>March 2020</strong> | Actor for a Falabella advert produced by Twentyfour Seven.</li>
                            <li><strong>September 2018 y November 2019</strong> | Model for the Zatzaizkit clothing brand.</li>
                        </ul>
                    </section>
                    <section id="personal-data">
                        <h1>Personal data</h1>
                        <ul>
                            <li>Languages: <strong>Spanish, English, Basque, Italian, Catalan and French</strong></li>
                            <li>Skills: <strong>Singing (tenor), Football Freestyle, Surfskate, Surf, Urban dancing</strong></li>
                            <li>Height: <strong>180cm</strong></li>
                            <li>Weight: <strong>80 kg</strong></li>
                            <li>Eye colour: <strong>Blue</strong></li>
                            <li>Hair colour: <strong>Light brown</strong></li>
                        </ul>
                    </section>
                </div>
    }
    else {
        text = <div className="cv">
                    <section id="cinema-tv">
                        <h1>Cine y TV</h1>
                        <ul>
                            <li><strong>Junio 2023</strong> | Personaje de "Kurt Ralske" en la película <strong>Segundo Premio</strong>, dirigida por <strong>Isaki Lacuesta</strong>.</li>
                            <li><strong>Junio 2024</strong> | Personaje de "Jaime" en la serie de Disney+ <strong>Olimpia</strong>, dirigida por <strong>Violeta Salama</strong>.</li>
                            <li><strong>Marzo 2021</strong> | Personaje de "Adrián Alcántara" en la serie <strong>Cuéntame Cómo Pasó</strong> de <strong>Televisión Española</strong>.</li>
                            <li><strong>Febrero 2021</strong> | Pequeño papel en la película <strong>Maixabel</strong>, dirigida por <strong>Icíar Bollaín</strong>.</li>
                            <li><strong>Agosto 2021</strong> | Personaje de camarero en la película <strong>La Restauración</strong>, dirigida por <strong>David Mateo</strong>.</li>
                            <li><strong>Abril 2021</strong> | Personaje de "Martin" en la serie <strong>Larrugorritan</strong> dirigida por <strong>Jone Arriola</strong>.</li>
                            <li><strong>Diciembre 2020</strong> | Director y actor protagonista para el corto <strong>El Sorteo de Navidad</strong>. Mención especial en el concurso de Navidad de Cultura Inquieta.</li>
                            <li><strong>Noviembre 2020</strong> | Cocreador y actor principal para el videoclip <strong>Pareidolia</strong> de la banda Habi.</li>
                            <li><strong>Mayo 2020</strong> | Director y actor protagonista para el corto experimental <strong>Estado de Contención</strong>, ganador del premio al corto más loco en el Concurso Amokna organizado por la Escuela de Creación Escenica de Madrid.</li>
                        </ul>
                    </section>
                    <section id="theatre">
                        <h1>Teatro</h1>
                        <ul>
                            <li><strong>Junio 2022 - Junio 2024</strong> | Personaje de "Policía" en la obra <strong>Napolitana</strong> dirigida por <strong>Marta Barrera</strong>.</li>
                            <li><strong>Noviembre 2021</strong> | Lecturas Dramatizadas de los textos "Granada" de Yanis Klavrianós, "Las Cosas Que Llevas Contigo" de Andreas Flurakis y "Antígona Un Requiem" de Thomas Kock dirigido por <strong>Víctor Velasco</strong> para la Asamblea de Eurodram.</li>
                            <li><strong>Junio 2021</strong> | Personaje de Lisandro en la obra <strong>Sueño. Noche. Verano.</strong> dirigida por <strong>Gabriel Olivares</strong>.</li>
                            <li><strong>Julio 2021 - Agosto 2021</strong> | Personaje protagonista en la adaptación teatral de <strong>El juicio de Joe Harlan Junior</strong> de JK Franko, dirigida por <strong>Tony Marturano</strong>.</li>
                        </ul>
                    </section>
                    <section id="education">
                        <h1>Formación</h1>
                        <ul>
                            <li><strong>Septiembre 2020 - Febrero 2023</strong> | Formación regular en Interpretación en la <strong>Escuela Work In Progress</strong> de Madrid dirigida por el director de teatro <strong>Darío Facal</strong>.</li>
                            <li><strong>Julio 2022</strong> | Taller "International Actors Bootcamp" impartido por <strong>Luci Lennox, Camilla-Valentine Isola, Sophie Holland y Nathalie Cheron</strong> en <strong>Frank Stein Studio</strong>.</li>
                            <li><strong>Enero 2022 - Febrero 2022</strong> | Taller del <strong>Teatro Español</strong> “El gesto sonoro. La palabra en movimiento” con <strong>Natalia Menéndez, Mey-Ling Bisogno y José Gabriel López Antuñano</strong>.</li>
                            <li><strong>Febrero 2023 - Abril 2023</strong> | Curso de "Acting in English" con la directora de casting <strong>Luci Lennox y Camilla-Valentina Isola</strong>.</li>
                            <li><strong>Octubre 2023 - Febrero 2024</strong> | Curso de "Acting in English" con el actor <strong>David Luque</strong> en la Escuela Work In Progress de Madrid.</li>
                            <li><strong>Octubre 2023 - Febrero 2024</strong> | Curso de "Método Lee Strasberg" con la actriz <strong>Bárbara Santa-Cruz</strong> en la Escuela Work In Progress de Madrid.</li>
                            <li><strong>Marzo 2022 - Mayo 2022</strong> | Curso de "Método Meisner" con la actriz <strong>Yolanda Vega</strong> en la Escuela Work In Progress de Madrid.</li>
                            <li><strong>Junio 2021 - Octubre 2021</strong> | Training de Suzuki y Viewpoints con Gabriel Olivares y Andrés Acevedo en el centro <strong>TeatroLAB</strong>.</li>
                            <li><strong>Junio 2019</strong> | Graduado en Interpretación en la <strong>Escuela de Cine Internacional de Barcelona (ECIB)</strong>.</li>
                            <li><strong>Junio 2021 - Junio 2022</strong> | Entrenamiento para actores y actrices dirigido por Andoni Larrabeiti en la compañía <strong>LaJoven</strong>.</li>
                            <li><strong>Febrero 2021 - Mayo 2021</strong> | Curso de Teatro Musical con la actriz de musical <strong>Carmen Conesa</strong> en la Escuela Work In Progress de Madrid.</li>
                            <li><strong>Abril 2020</strong> | Taller de Marketing del Actor con el director de cine <strong>David Victori</strong>.</li>
                        </ul>
                    </section>
                    <section id="advertising">
                        <h1>Publicidad</h1>
                        <ul>
                            <li><strong>Diciembre 2022</strong> | Actor para spot publicitario de Laboral Kutxa dirigido por Asier Urbieta.</li>
                            <li><strong>Febrero 2022</strong> | Actor para spot publicitario de San Miguel Magna.</li>
                            <li><strong>Diciembre 2021</strong> | Actor para campaña de redes sociales de la linea de ropa de “El Juego del Calamar” de Pull&Bear.</li>
                            <li><strong>Diciembre 2021</strong> | Actor para spot publicitario de ING Direct producido por Lee Films.</li>
                            <li><strong>Mayo 2021</strong> | Actor protagonista para el spot de VIPS dirigido por César Conti.</li>
                            <li><strong>Julio 2020</strong> | Actor principal para el spot de Euromillones dirigido por Félix Fernández de Castro.</li>
                            <li><strong>Julio 2020</strong> | Actor para spot publicitario de Caser Seguros dirigido por Bernat Lliteras.</li>
                            <li><strong>Junio 2020</strong> | Actor para campaña publicitaria de Puma y Valencia CF dirigido por Mario Lerma.</li>
                            <li><strong>Marzo 2020</strong> | Actor para campaña publicitaria de Falabella producido por Twentyfour Seven.</li>
                            <li><strong>Septiembre 2018 y Noviembre 2019</strong> | Modelo para la marca de ropa Zatzaizkit.</li>
                        </ul>
                    </section>
                    <section id="personal-data">
                        <h1>Datos Personales</h1>
                        <ul>
                            <li>Idiomas: <strong>Español, Inglés, Euskera, Italiano, Catalán y Francés</strong></li>
                            <li>Habilidades: <strong>Canto (tenor), Fútbol Freestyle, Surfskate, Surf, Baile Urbano</strong></li>
                            <li>Estatura: <strong>180 cm</strong></li>
                            <li>Peso: <strong>80 kg</strong></li>
                            <li>Color de ojos: <strong>Azules</strong></li>
                            <li>Color de pelo: <strong>Castaño Claro</strong></li>
                        </ul>
                    </section>
                </div>
    }
    return (
        <div className="container">
            {text}
        </div>
    );
}

export default Cv;