// import { useLanguage } from './LanguageContext';

function Showreel() {
    // const { language } = useLanguage();

    // let showreel = "";

    // if (language === "EN") {
    //     showreel = "Showreel";
    // } else if (language === "EU") {
    //     showreel = "Bideobook-a";
    // } else {
    //     showreel = "Videobook";
    // }

    return (
        <div className="showreel">
            {/* <section id="video-heading">
                <h1>{showreel}</h1>
            </section> */}
            <section id="video">
                <iframe width="1008" height="567" src="https://www.youtube.com/embed/RyGj8_gY9_I?si=4ya7ea2TJxLHnkat" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </section>
        </div>
    );
}

export default Showreel;