import './assets/css/styles.css';
import Main from './components/Main';
import Showreel from './components/Showreel';
import Photos from './components/Photos';
import Cv from './components/Cv';
import Press from './components/Press';
import Contact from './components/Contact';
import {Routes, Route, Link} from 'react-router-dom';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Mainsm from './components/Mainsm';
import Showreelsm from './components/Showreelsm';
import Footer from './components/Footer';
import Footersm from './components/Footersm';
import Menu from './components/Menu';
import Presssm from './components/Presssm';
import Contactsm from './components/Contactsm';
import julenclarkelogo from './assets/img/julenclarkelogo.png'

function App() {
  const [showMenu, setMenu] = useState(false);

  //const handleClick = () => {setMenu(!showMenu)}

  return (
    <div className="App">
      <html lang="en">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />

          <title>Julen Clarke</title>
          <meta name="description" content="Julen Clarke | Actor" />
          <meta name="author" content="Julen Clarke" />

          {/* <!-- Open Graph Protocol --> */}
          <meta property="og:title" content="Julen Clarke" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="assets/img/julenclarkeicon.png" />
          <meta property="og:url" content="https://www.julenclarke.com" />
          <meta property="og:description" content="Julen Clarke | Actor" />
          <meta property="og:locale" content="es_ES" />
          <meta property="og:site_name" content="Julen Clarke" />

          <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
            integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
            crossOrigin="anonymous"
            disabled
          />

          <link rel="icon" type="image/png" href={julenclarkelogo} />

          {/* <!-- Preload --> */}
          {/* <link
            rel="preload"
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
            as="style"
          /> */}
          {/* <link rel="preload" href={julenclarkelogo} as="image"/>
          <link rel="preload" href={require("./assets/img/main_photo.webp")} as="image"/>
          <link rel="preload" href="https://www.youtube.com/embed/LYA18QeJK8Y?si=nlOwlEJo07zb-xOn" as="document"/>
          <link rel="preload" href={require("./assets/img/photoone.webp")} as="image"/>
          <link rel="preload" href={require("./assets/img/phototwo.webp")} as="image"/>
          <link rel="preload" href={require("./assets/img/photothree.webp")} as="image"/>
          <link rel="preload" href={require("./assets/img/photofour.webp")} as="image"/>
          <link rel="preload" href={require("./assets/img/photofive.webp")} as="image"/>
          <link rel="preload" href={require("./assets/img/photosix.webp")} as="image"/>
          <link rel="preload" href={require("./assets/img/photoseven.webp")} as="image"/>
          <link rel="preload" href={require("./assets/img/photoeight.webp")} as="image"/>
          <link rel="preload" href={require("./assets/img/photonine.webp")} as="image"/>
          <link rel="preload" href={require("./assets/img/phototen.webp")} as="image"/>
          <link rel="preload" href={require("./assets/img/phototwelve.webp")} as="image"/>
          <link rel="preload" href={require("./assets/img/photoeleven.webp")} as="image"/>
          <link rel="preload" href={require("./assets/img/pressone.webp")} as="image"/>
          <link rel="preload" href={require("./assets/img/presstwo.webp")} as="image"/>
          <link rel="preload" href={require("./assets/img/pressthree.webp")} as="image"/>
          <link rel="preload" href={require("./assets/img/pressfour.webp")} as="image"/>
          <link rel="preload" href={require("./assets/img/pressfive.webp")} as="image"/> */}

        </head>
        <body>
          <header>
            <div className='d-md-none'>
              <div className="header-sm">
                <div className="header-logo">
                  <Link onClick={() => setMenu(false)} to="/" className="nav-item">
                    <img width={200} src={require("./assets/img/julenclarkelogo.png")} alt="Julen Clarke logo" />
                  </Link>
                </div>
                <div className='header-menu' onClick={() => setMenu(!showMenu)}>
                  {showMenu ? <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                              </svg>
                            : <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                              </svg>
                  }
                </div>
              </div>
            </div>
            <div className='d-none d-md-block'>
              <Header />
            </div>
          </header>
          <div className='d-md-none'>
            <Routes>
              <Route path="/" element={!showMenu ? <Mainsm /> : <Menu />}></Route>
              <Route path="/showreel" element={!showMenu ? <Showreelsm /> : <Menu setMenu={() => setMenu(false)} />}></Route>
              <Route path="/photos" element={!showMenu ? <Photos /> : <Menu setMenu={() => setMenu(false)} />}></Route>
              <Route path="/cv" element={!showMenu ? <Cv /> : <Menu setMenu={() => setMenu(false)} />}></Route>
              <Route path="/press" element={!showMenu ? <Presssm /> : <Menu setMenu={() => setMenu(false)} />}></Route>
              <Route path="/contact" element={!showMenu ? <Contactsm /> : <Menu setMenu={() => setMenu(false)} />}></Route>
            </Routes>
          </div>
          <div className='d-none d-md-block'>
            <Routes>
              <Route path="/" element={<Main />}></Route>
              <Route path="/showreel" element={<Showreel />}></Route>
              <Route path="/photos" element={<Photos />}></Route>
              <Route path="/cv" element={<Cv />}></Route>
              <Route path="/press" element={<Press />}></Route>
              <Route path="/contact" element={<Contact />}></Route>
            </Routes>
          </div>
          <footer>
            <div className='d-md-none'>
              {!showMenu ? <Footersm /> : <div className='d-none' />}
            </div>
            <div className='d-none d-md-block'>
              <Footer />
            </div>
          </footer>
        </body>
      </html>
    </div>
  );
}

export default App;
