import { useLanguage } from './LanguageContext';

function Main() {
    const { language } = useLanguage();

    let text = "", showreel = "";

    if (language === "EN") {
        showreel = "Showreel";
        text = <p>I am a software engineer who, some years ago, decided to start a career in acting to exploit my creativity to the fullest, a creativity that I felt restricted in my job at that time.<br/><br/>Almost 5 years after making that decision, 2023 became the most important year of my career: I got my first important role in a feature film, playing the character of <em>Kurt Ralske</em> in the film <strong>'Segundo Premio'</strong> directed by <strong>Isaki Lacuesta</strong>, and <strong>winner of the Golden 'Biznaga' at the 2024 Malaga Film Festival</strong>; with the 
        comedy <strong>'Napolitana'</strong>, where I have the role of <i>Police</i>, we managed to be selected in the international theatre festival <strong>UWE: Munich Festival</strong>; and finally, I graduated from the <strong>Work In Progress Acting School</strong>, where for almost 3 years I trained with professionals such as <strong>Darío Facal, Ernesto Arias, Carmen Conesa, Ana Telenti and María Herrero</strong> among others.<br/><br/>Before 2023, my most significant work was in the TVE (Spanish TV) series <strong>'Cuéntame Como Pasó'</strong>, where I gave life to the character 'Adrián Alcántara'.
        In March 2021, I was also lucky enough to participate in a small part of the film <strong>'Maixabel'</strong>, directed by <strong>Icíar Bollaín</strong> and sharing scene with <strong>Blanca Portillo</strong>.<br/><br/>
        In July 2022, I decided to complement the training at Work In Progress by participating in the first <strong>Frank Stein Studio</strong> international actors workshop with casting directors such as <strong>Luci Lennox, Camilla-Valentine Isola, Sophie Holland or Nathalie Cheron</strong>.<br/><br/>
        Apart from these trainings, I have also participated in different theatre trainings from important theatre organizations such as the <strong>'Teatro Español' (Spanish Theatre)</strong>, <strong>'TeatroLAB'</strong> performance creation space or <strong>'LaJoven Compañía'</strong>, with professionals such as <strong>Gabriel Olivares, Natalia Menéndez, Andoni Larrabeiti, Mey-Ling Bisogno and José Gabriel López Antuñano</strong>.<br/><br/>
        With <strong>Gabriel Olivares</strong>, I had the opportunity to participate in the adaptation <strong>'Sueño. Noche. Verano.'</strong> from Shakespeare's classic play, Midsummer's Night Dream.<br/><br/>
        After the training and experience acquired in theatre, together with two colleagues I decided to found the theatre company <strong>'Outsiders Teatro'</strong>, a company with which in June 2022 I premiered the aforementioned comedy <strong>'Napolitana'</strong>, written by <strong>Daniel Cuervo</strong> and directed by <strong>Marta Barrera</strong>, and with which we have reached <strong>50 performances</strong> and we have been selected in the <strong>WE:NOW International Arts Festival of Madrid</strong>.<br/><br/>
        Finally, I have written, directed and starred in three short films, two of which have received awards in different competitions. The short film <strong>'El Sorteo de Navidad'</strong> received a special mention in the <strong>'Cultura Inquieta' Christmas short film competition</strong>, and the experimental short film <strong>'Estado de Contención'</strong> received the <strong>award for craziest short film in the 'Amokna' competition</strong>, organized by <strong>'Escuela de Creación Escenica de Madrid'</strong>.</p>
    // } else if (language === "EU") {
    //     videobook = "Videobook-a";
    //     text = <p>Soy un ingeniero de software que decidió comenzar una carrera en el mundo de la interpretación con la motivación de explotar al máximo mi creatividad, una creatividad que sentía restringida en mi puesto de trabajo de aquel entonces.<br/><br/>Casi 5 años después de haber tomado aquella decisión, 2023 se convierte en el año más importante de mi carrera: obtengo mi primer papel importante en un largometraje, encarnando a <i>Kurt Ralske</i> en la película <strong><i>Segundo Premio</i></strong> dirigida por <strong>Isaki Lacuesta</strong>; con la 
    //     comedia <strong><i>Napolitana</i></strong>, donde tengo el papel de <i>Policía</i>, conseguimos ser seleccionados en el festival internacional de teatro <strong>UWE:Festival de Múnich</strong>; y por último, me gradúo en el <strong>Estudio Work In Progress</strong>, donde durante casi 3 años me formo con profesionales como <strong>Darío Facal, Ernesto Arias, Carmen Conesa, Ana Telenti y María Herrero</strong> entre otros.<br/><br/>Antes del 2023, mi trabajo más sonado fue en la serie de TVE <strong><i>Cuéntame Cómo Pasó</i></strong>, donde di vida al personaje Adrián Alcántara.
    //     En agosto del 2021, participé en el largometraje independiente <strong><i>Those We Used To Be</i></strong>, filmada en inglés y dirigido por <strong>David Mateo</strong>, donde tuve un papel importante en la película. En marzo del 2021, también tuve la suerte de participar en una pequeña parte de la película <strong><i>Maixabel</i></strong>, dirigida por <strong>Icíar Bollaín</strong> y compartiendo escena con <strong>Blanca Portillo</strong>.<br/><br/>
    //     En julio de 2022, decidí complementar la formación en Work In Progress participando en el primer taller de actores internacionales de <strong>Frank Stein Studio</strong> con directoras de casting como <strong>Luci Lennox, Camilla-Valentine Isola, Sophie Holland o Nathalie Cheron</strong>.<br/><br/>
    //     Aparte de mi formación en Interpretación, también he participado en diferentes trainings de teatro de importantes organizaciones teatrales como el <strong>Teatro Español</strong>, el espacio de creación escénica <strong>TeatroLAB</strong> o <strong>LaJoven</strong> compañía, con profesionales como <strong>Gabriel Olivares, Natalia Menéndez, Andoni Larrabeiti, Mey-Ling Bisogno y José Gabriel López Antuñano</strong>.<br/><br/>
    //     Con <strong>Gabriel Olivares</strong>, tuve la oportunidad de participar en la adaptación <strong><em>Sueño. Noche. Verano.</em></strong>  de la clásica obra de Shakespeare.<br/><br/>
    //     Tras la formación y experiencia adquirida en teatro, junto con dos compañerxs decido fundar la compañía <strong>Outsiders Teatro</strong>, compañía con la que en junio de 2022 estrené la ya mencionada comedia <strong><em>Napolitana</em></strong>, escrita por <strong>Daniel Cuervo</strong> y dirigida por <strong>Marta Barrera</strong>,  y con la que también conseguimos un pleno de llenos en todas las funciones en el <strong>Teatro de las Aguas</strong>.<br/><br/>
    //     Por último, he escrito, dirigido y protagonizado tres cortometrajes, de los cuales dos de ellos han recibido premios en diferentes concursos. El corto <strong><em>El Sorteo de Navidad</em></strong> recibió una <strong><em>mención especial en el concurso de cortos de Navidad de Cultura Inquieta</em></strong>, y el corto experimental <strong><i>Estado de Contención</i></strong> recibió el <strong><em>premio al corto más loco en el concurso Amokna</em></strong>, organizado por la Escuela de Creación Escenica de Madrid.</p>
    } else {
        showreel = "Videobook";
        text = <p>Soy un ingeniero de software que, hace unos años, decidió comenzar una carrera en el mundo de la interpretación con la motivación de explotar al máximo mi creatividad, una creatividad que sentía restringida en mi puesto de trabajo de aquel entonces.<br/><br/>Casi 5 años después de haber tomado aquella decisión, 2023 se convierte en el año más importante de mi carrera: obtengo mi primer papel importante en un largometraje, encarnando a <i>Kurt Ralske</i> en la película <strong><i>Segundo Premio</i></strong> dirigida por <strong>Isaki Lacuesta</strong>, y <strong>ganadora de la Biznaga de Oro en el Festival de Málaga 2024</strong>; con la 
        comedia <strong><i>Napolitana</i></strong>, donde tengo el papel de <i>Policía</i>, conseguimos ser seleccionados en el festival internacional de teatro <strong>UWE:Festival de Múnich</strong>; y por último, me gradúo en el <strong>Estudio Work In Progress</strong>, donde durante casi 3 años me formo con profesionales como <strong>Darío Facal, Ernesto Arias, Carmen Conesa, Ana Telenti y María Herrero</strong> entre otros.<br/><br/>Antes del 2023, mi trabajo más significativo fue en la serie de TVE <strong><i>Cuéntame Cómo Pasó</i></strong>, donde di vida al personaje de Adrián Alcántara.
        Y en marzo del 2021, tuve la suerte de participar en una pequeña parte de la película <strong><i>Maixabel</i></strong>, dirigida por <strong>Icíar Bollaín</strong> y compartiendo escena con <strong>Blanca Portillo</strong>.<br/><br/>
        En julio de 2022, decidí complementar la formación en Work In Progress participando en el primer taller de actores internacionales de <strong>Frank Stein Studio</strong> con directoras de casting como <strong>Luci Lennox, Camilla-Valentine Isola, Sophie Holland o Nathalie Cheron</strong>.<br/><br/>
        Aparte de mi formación en Interpretación, también he participado en diferentes trainings de teatro de importantes organizaciones teatrales como el <strong>Teatro Español</strong>, el espacio de creación escénica <strong>TeatroLAB</strong> o <strong>LaJoven</strong> compañía, con profesionales como <strong>Gabriel Olivares, Natalia Menéndez, Andoni Larrabeiti, Mey-Ling Bisogno y José Gabriel López Antuñano</strong>.<br/><br/>
        Con <strong>Gabriel Olivares</strong>, tuve la oportunidad de participar en la adaptación <strong><em>Sueño. Noche. Verano.</em></strong>  de la clásica obra de Shakespeare.<br/><br/>
        Tras la formación y experiencia adquirida en teatro, junto con dos compañerxs decido fundar la compañía <strong>Outsiders Teatro</strong>, compañía con la que en junio de 2022 estrené la ya mencionada comedia <strong><em>Napolitana</em></strong>, escrita por <strong>Daniel Cuervo</strong> y dirigida por <strong>Marta Barrera</strong>, y con la que en 2024, hemos llegado a las <strong>50 funciones</strong>, y hemos sido <strong>seleccionados en el Festival Internacional de Artes de Madrid WE:NOW</strong>.<br/><br/>
        Por último, he escrito, dirigido y protagonizado tres cortometrajes, de los cuales dos de ellos han recibido premios en diferentes concursos. El corto <strong><em>El Sorteo de Navidad</em></strong> recibió una <strong><em>mención especial en el concurso de cortos de Navidad de Cultura Inquieta</em></strong>, y el corto experimental <strong><i>Estado de Contención</i></strong> recibió el <strong><em>premio al corto más loco en el concurso Amokna</em></strong>, organizado por la Escuela de Creación Escenica de Madrid.</p>
    }

    return (
        <div className="main">
            <section id="bio">
                <article>
                    <img id="main-photo" width={400} src={require("../assets/img/main_photo.webp")} alt="Here is Julen Clarke" decoding='async'/>
                    {text}
                </article>
            </section>
            <section id="showreel-main">
                <h1>{showreel}</h1>
            </section>
            <section id="video-main">
                <iframe width="1008" height="567" src="https://www.youtube.com/embed/RyGj8_gY9_I?si=4ya7ea2TJxLHnkat" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </section>
        </div>
    );
}

export default Main;