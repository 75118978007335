function Photos() {
    return (
        <div className="photos">
            <div className="photo-section">
                <img id="left-photo" src={require("../assets/img/photoone.jpg")} alt="Here is Julen Clarke" />
                <img id="right-photo" src={require("../assets/img/phototwo.jpg")} alt="Here is Julen Clarke" />
            </div>
            <div className="photo-section">
                <img id="left-photo" src={require("../assets/img/photothree.jpeg")} alt="Here is Julen Clarke" />
                <img id="right-photo" src={require("../assets/img/photofour.jpg")} alt="Here is Julen Clarke" />
            </div>
            <div className="photo-section">
                <img id="left-photo" src={require("../assets/img/photofive.jpg")} alt="Here is Julen Clarke" />
                <img id="right-photo" src={require("../assets/img/photosix.jpg")} alt="Here is Julen Clarke" />
            </div>
            <div className="photo-section">
                <img id="left-photo" src={require("../assets/img/photoseven.jpg")} alt="Here is Julen Clarke" />
                <img id="right-photo" src={require("../assets/img/photoeight.jpeg")} alt="Here is Julen Clarke" />
            </div>
            <div className="photo-section">
                <img id="left-photo" src={require("../assets/img/photonine.jpeg")} alt="Here is Julen Clarke" />
                <img id="right-photo" src={require("../assets/img/phototen.jpeg")} alt="Here is Julen Clarke" />
            </div>
            <div className="photo-section">
                <img id="left-photo" src={require("../assets/img/photoeleven.jpeg")} alt="Here is Julen Clarke" />
                <img id="right-photo" src={require("../assets/img/phototwelve.jpeg")} alt="Here is Julen Clarke" />
            </div>
            <div className="photo-section">
                <img id="left-photo" src={require("../assets/img/photothirteen.jpeg")} alt="Here is Julen Clarke" />
                <img id="right-photo" src={require("../assets/img/photofourteen.jpeg")} alt="Here is Julen Clarke" />
            </div>
        </div>
    );
}

export default Photos;