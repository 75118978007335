import { createContext, useContext, useState } from "react";

const LanguageContext = createContext(undefined)

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('ES');

    const updateLanguage = e => setLanguage(e.target.value);

    const [text] = useState(require('../assets/languagesdata.json'));

    // useEffect(() => { 
    //     fetch(`https://raw.githubusercontent.com/julenclarke/ejtos-react_budget_app/main/languagesdata.json`) 
    //       .then((response) => response.json()) 
    //       .then((jsonData) => setText(jsonData))
    //       .catch((error) => console.log(error)); 
    //   }, []); 

    return (
        <LanguageContext.Provider
            value={{
                language,
                updateLanguage: updateLanguage,
                text
            }}
        >
            {children}
        </LanguageContext.Provider>
    )
}

export const useLanguage = () => useContext(LanguageContext);