import { useLanguage } from './LanguageContext';

function Press() {
    const { language } = useLanguage();

    let text = "";

    if (language === "EN") {
        text = <div className="press">
                    <section id="even-news">
                        <div className="link">
                            <a href="https://www.produ.com/noticias/actor-julen-clark-el-streaming-nos-permite-unir-mercados-y-a-los-actores-nos-da-mas-trabajo#:~:text=El%20actor%20espa%C3%B1ol%20Julen%20Clark,de%20conectar%20a%20la%20industria."><h2>Julen Clarke: Streaming allows us to unite markets and gives us more work for actors</h2></a>
                            <p>— Produ</p>
                        </div>
                        <div className="news">
                            <img id="news-img" src={require("../assets/img/pressone.webp")} alt="Noticia Produ" decoding='async'/>
                        </div>
                    </section>
                    <section id="odd-news">
                        <div className="link">
                            <a href="https://estudio-workinprogress.com/julen-clarke-en-la-serie-cuentame-como-paso/"><h2>JULEN CLARKE IN THE TV SERIES 'CUÉNTAME CÓMO PASO' - Our student plays Adrián Alcántara in the TVE 1 series</h2></a>
                            <p>— Work In Progress Acting School</p>
                        </div>
                        <div className="news">
                            <img id="news-img" src={require("../assets/img/presstwo.webp")} alt="Noticia Work In Progress" decoding='async'/>
                        </div>
                    </section>
                    <section id="even-news">
                        <div className="link">
                            <a href="https://culturainquieta.com/es/cine/item/17603-los-videos-ganadores-del-concurso-de-micro-cine-navidad-en-60-segundos.amp.html?__twitter_impression=true"><h2>The winning videos of the micro-cinema contest 'Christmas in 60 seconds'</h2></a>
                            <p>— Cultura Inquieta</p>
                        </div>
                        <div className="news">
                            <img id="news-img" src={require("../assets/img/pressthree.webp")} alt="Noticia Cultura Inquieta" decoding='async'/>
                        </div>
                    </section>
                    <section id="odd-news">
                        <div className="link">
                            <a href="https://www.casting.es/temoignages/274-julen-un-nuevo-talento-para-seguir-de-cerca"><h2>Julen, a new talent to follow closely</h2></a>
                            <p>— casting.es</p>
                        </div>
                        <div className="news">
                            <img id="news-img" src={require("../assets/img/pressfour.webp")} alt="Noticia Casting.es" decoding='async'/>
                        </div>
                    </section>
                    <section id="even-news">
                        <div className="link">
                            <a href="https://www.diariovasco.com/gipuzkoademoda/conoce-modelos-actores-futuro-20200930182558-ga.html"><h2>Meet the models and actors of the future</h2></a>
                            <p>— El Diario Vasco</p>
                        </div>
                        <div className="news">
                            <img id="news-img" src={require("../assets/img/pressfive.webp")} alt="Noticia Casting.es" decoding='async'/>
                        </div>
                    </section>
                </div>
    } else {
        text = <div className="press">
            <section id="even-news">
                <div className="link">
                    <a href="https://www.produ.com/noticias/actor-julen-clark-el-streaming-nos-permite-unir-mercados-y-a-los-actores-nos-da-mas-trabajo#:~:text=El%20actor%20espa%C3%B1ol%20Julen%20Clark,de%20conectar%20a%20la%20industria."><h2>Actor Julen Clarke: El streaming nos permite unir mercados y a los actores nos da más trabajo</h2></a>
                    <p>— Produ</p>
                </div>
                <div className="news">
                    <img id="news-img" src={require("../assets/img/pressone.webp")} alt="Noticia Produ" decoding='async'/>
                </div>
            </section>
            <section id="odd-news">
                <div className="link">
                    <a href="https://estudio-workinprogress.com/julen-clarke-en-la-serie-cuentame-como-paso/"><h2>JULEN CLARKE EN LA SERIE ‘CUÉNTAME CÓMO PASÓ’ - Nuestro alumno interpreta a Adrián Alcántara en la serie de TVE 1</h2></a>
                    <p>— Estudio Work In Progress</p>
                </div>
                <div className="news">
                    <img id="news-img" src={require("../assets/img/presstwo.webp")} alt="Noticia Work In Progress" decoding='async'/>
                </div>
            </section>
            <section id="even-news">
                <div className="link">
                    <a href="https://culturainquieta.com/es/cine/item/17603-los-videos-ganadores-del-concurso-de-micro-cine-navidad-en-60-segundos.amp.html?__twitter_impression=true"><h2>Los vídeos ganadores del concurso de micro-cine 'Navidad en 60 segundos'</h2></a>
                    <p>— Cultura Inquieta</p>
                </div>
                <div className="news">
                    <img id="news-img" src={require("../assets/img/pressthree.webp")} alt="Noticia Cultura Inquieta" decoding='async'/>
                </div>
            </section>
            <section id="odd-news">
                <div className="link">
                    <a href="https://www.casting.es/temoignages/274-julen-un-nuevo-talento-para-seguir-de-cerca"><h2>Julen, un nuevo talento para seguir de cerca</h2></a>
                    <p>— casting.es</p>
                </div>
                <div className="news">
                    <img id="news-img" src={require("../assets/img/pressfour.webp")} alt="Noticia Casting.es" decoding='async'/>
                </div>
            </section>
            <section id="even-news">
                <div className="link">
                    <a href="https://www.diariovasco.com/gipuzkoademoda/conoce-modelos-actores-futuro-20200930182558-ga.html"><h2>Conoce a los modelos y actores de futuro</h2></a>
                    <p>— El Diario Vasco</p>
                </div>
                <div className="news">
                    <img id="news-img" src={require("../assets/img/pressfive.webp")} alt="Noticia Casting.es" decoding='async'/>
                </div>
            </section>
        </div>
    }

    return (
        <div className='container'>
            {text}
        </div>
    );
}

export default Press;